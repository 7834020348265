import React, { Component } from 'react';
import Select from 'react-windowed-select';
import CreatableSelect from 'react-select/creatable';
import { Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import Universities from '../assets/dropdowns/Universities.json'
import Majors from '../assets/dropdowns/Majors.json';
import Genders from '../assets/dropdowns/Genders.json';
import Pronouns from '../assets/dropdowns/Pronouns.json';
import Ethnicities from '../assets/dropdowns/Ethnicities.json';
import Graduation from '../assets/dropdowns/Graduation.json';
import Topics from '../assets/dropdowns/Topics.json';

class RegistrationPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      gender: '',
      genderOther: '',
      ethnicity: '',
      ethnicityOther: '',
      pronouns: '',
      pronounsOther: '',
      email: '',
      phoneNumber: '',
      major: '',
      majorOther: '',
      github: '',
      linkedin: '',
      school: '',
      schoolOther: '',
      graduationDate: '',
      whyKnightHacks: '',
      whatLearn: [],
      information: false,
      code: false,
      error: '',
      redirectTo: null,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleArrChange = this.handleArrChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSelectChange = (e) => this.setState({ [e.name]: e.value });

  handleArrChange = (e) => {
    if (e) {
      const learn = e.map((element) => {
        return element.value;
      })
      this.setState({ whatLearn: learn });
    } else {
      this.setState({ whatLearn: [] });
    }
  }

  handleCheckChange = (e) => {
    const current = e.target.value === 'false' ? false : true;
    this.setState({ [e.target.name]: !current })
  }

  validatePhone = (phone) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(phone.match(phoneno)) {
      return true;
    }
    return false;
  }

  handleSubmit = (e) => {
    this.setState({ error: '' });
    e.preventDefault();
    const {
      firstName,
      lastName,
      gender,
      genderOther,
      ethnicity,
      ethnicityOther,
      pronouns,
      pronounsOther,
      email,
      phoneNumber,
      major,
      majorOther,
      github,
      linkedin,
      school,
      schoolOther,
      graduationDate,
      whyKnightHacks,
      whatLearn,
      information,
      code,
    } = this.state;
    let acceptedFormFields = false;
    if (information && code) {
      acceptedFormFields = true;
    }
    const file = this.fileInput.current.files[0] !== undefined ? this.fileInput.current.files[0] : null;
    let genderVal = gender;
    let ethnicityVal = ethnicity;
    let pronounsVal = pronouns;
    let schoolVal = school;
    let majorVal = major;
    if (gender === 'Other...') {
      genderVal = genderOther;
    }
    if (ethnicity === 'Other...') {
      ethnicityVal = ethnicityOther;
    }
    if (pronouns === 'Other...') {
      pronounsVal = pronounsOther;
    }
    if (school === 'Other...') {
      schoolVal = schoolOther;
    }
    if (major === 'Other...') {
      majorVal = majorOther;
    }
    if (!this.validatePhone(phoneNumber)) {
      this.setState({ error: `Error: one or both of the phone numbers you entered were invalid.` })
      return;
    }
    if (file != null) {
      var regexForGrabbingExt = /(?:\.([^.]+))?$/;
      var extension = regexForGrabbingExt.exec(file.name)[1];
      if (extension !== 'pdf') {
        this.setState({ error: `Error: resume uploads must be in pdf format.` })
        return;
      }
    }
    const dataForm = new FormData();
    dataForm.append('firstName', firstName);
    dataForm.append('lastName', lastName);
    dataForm.append('gender', genderVal);
    dataForm.append('ethnicity', ethnicityVal);
    dataForm.append('pronouns', pronounsVal);
    dataForm.append('dietary', 'N/A');
    dataForm.append('email', email);
    dataForm.append('phoneNumber', phoneNumber);
    dataForm.append('major', majorVal);
    dataForm.append('github', github);
    dataForm.append('linkedin', linkedin);
    dataForm.append('school', schoolVal);
    dataForm.append('graduationDate', graduationDate);
    dataForm.append('whyKnightHacks', whyKnightHacks);
    dataForm.append('whatLearn', whatLearn);
    dataForm.append('willNeedTravelAssistance', false);
    dataForm.append('emergencyFirstName', 'N/A');
    dataForm.append('emergencyLastName', 'N/A');
    dataForm.append('emergencyRelationship', 'N/A');
    dataForm.append('emergencyPhoneNumber', '1111111111');
    dataForm.append('emergencyEmail', 'null@nullmailer.com');
    dataForm.append('acceptedFormFields', acceptedFormFields);
    dataForm.append('resume', file);
    
    

    // fetch('/api/register/', {
    //   method: 'POST',
    //   body: dataForm
    // })
    // .then((result) => result.text())
    // .then((res) => {
    //   if (res.length === 0) {
    //     this.setState({
    //       redirectTo: '/accepted'
    //     })
    //   } else {
    //     this.setState({
    //       error: `Error: ${res}`
    //     });
    //   }
    // })
    // .catch(function(err){ 
    //   console.log(err);
    // })
  }

  render() {
    const {
      firstName,
      lastName,
      gender,
      genderOther,
      ethnicity,
      ethnicityOther,
      pronouns,
      pronounsOther,
      email,
      phoneNumber,
      school,
      schoolOther,
      major,
      majorOther,
      github,
      linkedin,
      graduationDate,
      whatLearn,
      whyKnightHacks,
      information,
      code,
      error,
      redirectTo,
    } = this.state;

    const file = this.fileInput.current

		if (redirectTo) {
			return <Redirect to={{ pathname: redirectTo }} />;
    }

    return (
      <div className='kh-registration-page'>
        <div className = 'container'>
          <div className = 'header'>
            Knight Hacks 2020 Registration
          </div>
          <div className='row mx-2'>
            <div className='col-12 col-sm-6'>
              <Button  className = "submit-button" href = "https://docs.google.com/forms/d/e/1FAIpQLSefoX7WvY1WT28hIA85x1PTs85vaLi1_f3xL40F3ittqmIW_g/viewform">Volunteer Form</Button>
            </div>
            <div className='col-12 col-sm-6'>
              <Button className = "submit-button" href = "https://forms.gle/39GwpJDEsiCz9AGE8">Mentor Form</Button>
            </div>
          </div>
          <form onSubmit={this.handleSubmit} autoComplete="false">
            <div className="row m-1">
              <div className = 'col-12 registration-field'>
                <label>
                  First name <span className="req">*</span> 
                </label>
                <input type="text" name="firstName" autoComplete="off" value={firstName} onChange={this.handleChange} required />
              </div>
              <div className = 'col-12 registration-field'>
                <label> 
                  Last name <span className="req">*</span> 
                </label>
                <input type="text" name="lastName" value={lastName} onChange={this.handleChange} required />
              </div>
              <div className = "col-12 registration-field">
                <label>
                  Gender <span className="req">*</span> 
                </label>
                <Select name="gender" onChange = {this.handleSelectChange} className="select" classNamePrefix="select" options={Genders} />
                <input tabIndex={-1} autoComplete="off" style={{ opacity: 0, height: 0 }} value={gender} required />
                <input name="genderOther" className="mt-2" placeholder="Other" value={genderOther} onChange={this.handleChange} style={{ display: `${gender === 'Other...' ? 'block' : 'none'}` }}/>
              </div>
              <div className="col-12 registration-field">
                  <label>
                    Ethnicity <span className="req">*</span> 
                  </label>
                  <Select name="ethnicity" onChange = {this.handleSelectChange} className="select" classNamePrefix="select" options={Ethnicities} required/>
                  <input tabIndex={-1} autoComplete="off" style={{ opacity: 0, height: 0 }} value={ethnicity} required />
                  <input name="ethnicityOther" className="mt-2" placeholder="Other" value={ethnicityOther} onChange={this.handleChange} style={{ display: `${ethnicity === 'Other...' ? 'block' : 'none'}` }}/>
              </div>
              <div className="col-12 registration-field">
                <label>
                  Prefered Pronouns
                </label>
                <Select name="pronouns" onChange = {this.handleSelectChange} className="select" classNamePrefix="select" options={Pronouns} />
                <input name="pronounsOther" className="mt-2" placeholder="Other" value={pronounsOther} onChange={this.handleChange} style={{ display: `${pronouns === 'Other...' ? 'block' : 'none'}` }}/>
              </div>
              <div className="col-12 registration-field">
                <label>
                  Email <span className="req">*</span> 
                </label>
                <input type="email" name="email" value = {email} onChange = {this.handleChange} required/>
              </div>
              <div className="col-12 registration-field">
                <label>
                  Phone <span className="req">*</span> 
                </label>
                <input type="phone" name="phoneNumber" value={phoneNumber} onChange = {this.handleChange} required/>
              </div>
              <div className="col-12 registration-field">
                <label>
                  Major / Field of Study <span className="req">*</span> 
                </label>
                <Select name="major" onChange = {this.handleSelectChange} className="select" classNamePrefix="select" options={Majors} required/>
                <input tabIndex={-1} autoComplete="off" style={{ opacity: 0, height: 0 }} value={major} required />
                <input name="majorOther" className="mt-2" placeholder="Other" value={majorOther} onChange={this.handleChange} style={{ display: `${major === 'Other...' ? 'block' : 'none'}` }}/>
              </div>
              <div className="col-12 registration-field">
                <label>
                  College <span className="req">*</span> 
                </label>
                <Select name="school" onChange = {this.handleSelectChange} className="select" classNamePrefix="select" options={Universities} windowThreshold={50} required/>
                <input tabIndex={-1} autoComplete="off" style={{ opacity: 0, height: 0 }} value={school} required />
                <input name="schoolOther" className="mt-2" placeholder="Other" value={schoolOther} onChange={this.handleChange} style={{ display: `${school === 'Other...' ? 'block' : 'none'}` }}/>
              </div>
              <div className="col-12 registration-field">
                <label>
                  Expected graduation date <span className="req">*</span> 
                </label>
                <Select name="graduationDate" onChange = {this.handleSelectChange} className="select" classNamePrefix="select" options={Graduation} windowThreshold={50} required/>
                <input tabIndex={-1} autoComplete="off" style={{ opacity: 0, height: 0 }} value={graduationDate} required />
              </div>
              <div className = "col-12 registration-field">
                <label>
                  Why do you want to attend Knight Hacks? (200 character limit) <span className="req">*</span> 
                </label>
                <textarea name ="whyKnightHacks" value={whyKnightHacks} onChange={this.handleChange} type = "text" maxLength = "200"/>
                <input tabIndex={-1} autoComplete="off" style={{ opacity: 0, height: 0 }} value={whyKnightHacks} required />
              </div>
              <div className = "col-12 registration-field">
                <label>
                  What do you want to learn at Knight Hacks? <span className="req">*</span> 
                </label>
                <CreatableSelect name="whatLearn" isMulti onChange={this.handleArrChange} className="select" classNamePrefix="select" options={Topics} required />
                <input tabIndex={-1} autoComplete="off" style={{ opacity: 0, height: 0 }} value={whatLearn} required />
              </div>
              <div className="col-12 registration-field">
                <label>
                  Linkedin
                </label>
                <input type="text" name="linkedin" value={linkedin} onChange = {this.handleChange}/>
              </div>
              <div className="col-12 registration-field">
                <label>
                  Github
                </label>
                <input type="text" name="github" value={github} onChange = {this.handleChange}/>
              </div>
              <div className="col-12 registration-field">
                <label>Resume (PDF Format Required)</label>
                <label htmlFor="resume" className="btn file-upload">
                  Choose File
                </label>
                <input id="resume" name="resume" type="file" ref={this.fileInput} onChange={() => this.forceUpdate()} accept="application/pdf" style={{ display: 'none' }} />
                <div>{`Current File: ${ file && file.files[0] !== undefined ? file.files[0].name : 'None'}`}</div>
              </div>
              <div className="col-12 registration-check">
                <input type = "checkbox" name = "code" value = {code} className="my-3" onChange = {this.handleCheckChange} required/>
                  {" I have read and agree to the"} <a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf" target="_blank" rel="noopener noreferrer">MLH Code of Conduct.</a>  
                <br/>
                <input type = "checkbox" name = "information" value = {information} onChange={this.handleCheckChange} required/>
                  {" I authorize you to share my application/registration information for event administration, ranking, MLH administration,\
                  pre- and post- event information e-mails, and occasional messages about hackathons in-line with the"} <a href = "https://mlh.io/privacy" rel="noopener noreferrer" target="_blank">MLH Privacy Policy</a>
                  {". I further agree to the terms of both the"} <a href = "https://github.com/MLH/mlh-policies/blob/master/prize-terms-and-conditions/contest-terms.md" rel="noopener noreferrer" target="_blank" >MLH Contest Terms and Conditions</a>
                  {" and the "}<a href ="https://mlh.io/privacy" rel="noopener noreferrer" target="_blank" >MLH Privacy Policy.</a>
              </div>
              <div className="col-12" style={{ color: 'red', textAlign: 'center' }}>{error}</div>
              <div className="col-12 registration-field">
                <Button className="submit-button" type = "submit"><a href="/">Submit Application</a></Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default RegistrationPage;