import React, { Component } from 'react';
import Clouds from '../assets/Home-Clouds.png';
import Sun from '../assets/Home-Sun.png';
import Water from '../assets/Home-Water.png';
import RegisterButton from './RegisterButton';
import AnimatedBoat from './AnimatedBoat';

class Home extends Component {
  render() {
    return (
      <div className="kh-home">
        <div className="register">
          <RegisterButton />
        </div>
        <img alt="sun" src={Sun} className="sun" />
        <img alt="clouds" src={Clouds} className="clouds" />
        <img alt="water" src={Water} className="water" />
        <div className="boat">
          <AnimatedBoat />
        </div>
      </div>
    )
  }
}

export default Home;