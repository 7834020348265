import React from 'react';
import Lottie from 'react-lottie';
import peopleData from './Boat-Characters.json';

const AnimatedBoat = ({ mobile }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: peopleData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <Lottie
    options={defaultOptions}
    height={350}
    width={mobile ? 300 : 350}
  />
  )
}

export default AnimatedBoat;