import React from 'react';
import Desktop from './components/Desktop';
import Mobile from './components/Mobile';
import RegistrationPage from './components/RegistrationPage';
import AcceptancePage from './components/AcceptancePage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
          <Route path="/register">
            <RegistrationPage />
          </Route>
          <Route path="/accepted">
            <AcceptancePage />
          </Route>
          <Route path="/slack" component={() => { 
            window.location.href = 'https://knighthacks.slack.com'; 
            return null;
          }} />
          <Route path="/facebook" component={() => { 
            window.location.href = 'https://www.facebook.com/KnightHacks/'; 
            return null;
          }} />
          <Route path="/instagram" component={() => { 
            window.location.href = 'https://www.instagram.com/KnightHacks/'; 
            return null;
          }} />
          <Route path="/discord" component={() => { 
            window.location.href = 'https://discord.gg/Kv5g9vf'; 
            return null;
          }} />
          <Route path="/twitter" component={() => { 
            window.location.href = 'https://www.twitter.com/KnightHacks/'; 
            return null;
          }} />
          <Route path="/marketing" component={() => {
            window.location.href = 'https://drive.google.com/open?id=1JfvcumDOMlS4YQuNS-6NvCh2cn52JrA2';
            return null;
          }} />
          <Route path="/resume" component={() => {
            window.location.href = 'https://forms.gle/p2TbagqardN9MPfC6';
            return null;
          }} />
          <Route path="/linktree" component={() => {
            window.location.href = 'https://linktr.ee/knighthacks';
            return null;
          }} />
          <Route path="/zoom" component={() => {
            window.location.href = 'https://ucf.zoom.us/j/95272477189?pwd=TlliV1gwRmxRajdzMmJ4N1ZhRmp4Zz09';
            return null;
          }} />
          <Route path="/membership" component={() => {
            window.location.href = 'https://forms.gle/5kA8gwazBQA1ei8z6';
            return null;
          }} />
          <Route path="/dues" component={() => {
            window.location.href = 'https://checkout.square.site/pay/864a3d6631a44a15aeebd65104a6f9e1';
            return null;
          }} />
          <Route path="/ops" component={() => {
            window.location.href = 'https://ucf.zoom.us/j/98281000067?pwd=TzNWRTdmMmc2TUk4MElSREhLRGp1UT09';
            return null;
          }} />
          <Route path="/feedback" component={() => {
            window.location.href = 'https://forms.gle/EfMQnpxb6SefA4tH7';
            return null;
          }} />
          <Route path="/">
            <div className="global">
              <div className="row no-gutters">
                <div className="col-12 desktop-media">
                  <Desktop />
                </div>
                <div className="col-12 mobile-media">
                  <Mobile />
                </div>
              </div>
            </div>
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
