import React from 'react';
import MaterialIcon from 'material-icons-react';
import { useHistory } from "react-router-dom";


const RegisterButton = () => {
  let history = useHistory();

  return (
    <div className="kh-register-button" onClick={ () => history.push("/register") }>
      REGISTER NOW <MaterialIcon icon="arrow_right" size="large" color='#DDC04A' />
    </div>
  )
}

export default RegisterButton;