import React, { Component } from 'react';
import Home from './Home';
import About from './About';
import Schedule from './Schedule';
import Register from './Register';
import Sponsor from './Sponsor';
import FAQ from './FAQ';
import RegisterButton from './RegisterButton';
import instagramWhite from '../assets/Instagram-White.svg';
import instagramBlack from '../assets/Instagram-Black.png';
import facebookWhite from '../assets/Facebook-White.svg';
import facebookBlack from '../assets/Facebook-Black.png';
import twitterWhite from '../assets/Twitter-White.svg';
import twitterBlack from '../assets/Twitter-Black.png';
import discordWhite from '../assets/Discord-White.png';
import discordBlack from '../assets/Discord-Black.png';
import logo from '../assets/Logo.svg';

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      register: false,
      FAQ: false,
    }
  }

  componentDidUpdate() {
    const { FAQ, register, page } = this.state;
    if (!register && page === 5) {
      this.setState({ register: true });
    }
    if (register && page !== 5) {
      this.setState({ register: false });
    }
    if (!FAQ && page === 4) {
      this.setState({ FAQ: true });
    }
    if (FAQ && page !== 4) {
      this.setState({ FAQ: false });
    }
  }

  changePage = (page) => this.setState({ page });

  renderPage = () => {
    const { page } = this.state;
    switch(page) {
      case 0:
        return <Home />;
      case 1:
        return <About />;
      case 2:
        return <Sponsor />;
      case 3:
        return <Schedule />;
      case 4:
        return <FAQ />;
      case 5:
        return <Register />;
      default:
        return null;
    }
  }

  render() {
    const { page, register, FAQ } = this.state;

    return (
      <div className="kh-holder">
        <div>
          {!FAQ ? (
            <div className="row logo-holder">
              <div className="col-12">
                <div style={{ position: 'static' }}>
                  <img alt="knight hacks logo" src={logo} />
                </div>
              </div>
              <div className="col-12">
                <div className="description">October 9th - October 11th, 2020</div>
              </div>
              {register
              ? (
                <div className="col-12">
                  <RegisterButton />
                </div>
              )
              : null
              }
            </div>
          )
          : null
          }
          {this.renderPage(page)}
          <div className="navigation">
            <div className="row">
              <a
                id="mlh-trust-badge"
                style={{ display: 'block', maxWidth: '100px', minWidth: '60px', position: 'fixed', right: '50px', top: 0, width: '10%', zIndex: 10000 }}
                href="https://mlh.io/seasons/na-2020/events?utm_source=na-hackathon&utm_medium=TrustBadge&utm_campaign=2020-season&utm_content=white"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src="https://s3.amazonaws.com/logged-assets/trust-badge/2020/mlh-trust-badge-2020-white.svg"
                  alt="Major League Hacking 2020 Hackathon Season"
                  style={{ width: '100%' }}
                />
              </a>
              <div className="col-12 navigation-row" style={{ color: `${page <= 2 ? 'white' : 'black'}` }}>
                <div
                  style={{ textDecoration: `${page === 0 ? 'underline' : 'none'}` }}
                  onClick={() => this.changePage(0)}>
                  Home
                </div>
                <div
                  style={{ textDecoration: `${page === 1 ? 'underline' : 'none'}` }}
                  onClick={() => this.changePage(1)}>
                  About
                </div>
                <div
                  style={{ textDecoration: `${page === 2 ? 'underline' : 'none'}` }}
                  onClick={() => this.changePage(2)}>
                  Sponsors
                </div>
                <div
                  style={{ textDecoration: `${page === 3 ? 'underline' : 'none'}` }}
                  onClick={() => this.changePage(3)}>
                  Schedule
                </div>
                <div
                  style={{ textDecoration: `${page === 4 ? 'underline' : 'none'}` }}
                  onClick={() => this.changePage(4)}>
                  FAQ
                </div>
                <div
                  style={{ textDecoration: `${page === 5 ? 'underline' : 'none'}` }}
                  onClick={() => this.changePage(5)}>
                  Register
                </div>
              </div>
              <div className="col-12 social-row">
                <div>
                  <img alt="instagram" src={page <= 2 ? instagramWhite : instagramBlack} onClick={() => { window.location = 'https://www.instagram.com/KnightHacks'}} />
                </div>
                <div>
                  <img alt="facebook" src={page <= 2 ? facebookWhite : facebookBlack} onClick={() => { window.location = 'https://www.facebook.com/KnightHacks'}} />
                </div>
                <div>
                  <img alt="twitter" src={page <= 2 ? twitterWhite : twitterBlack} onClick={() => { window.location = 'https://www.twitter.com/KnightHacks'}} />
                </div>
                <div>
                  <img alt="discord" src={page <= 2 ? discordWhite : discordBlack} onClick={() => { window.location = 'https://discord.gg/Kv5g9vf' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Desktop;