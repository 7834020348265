import React, { Component } from 'react';
import AnimatedBoat from './AnimatedBoat';

class Schedule extends Component {
  render() {
    return (
      <div className="kh-schedule">
        <div className="boat">
          <AnimatedBoat />
        </div>
        <div className="glacier">
        </div>
        <div className="description">
          <h2>Schedule</h2>
          <div className="schedule-holder">
            <div className="day">
              <p>Friday</p>
              <p>6:30 PM - Check-In</p>
              <p>7:00 PM - Dinner</p>
              <p>8:00 PM - Check-In Ends</p>
              <p>8:30 PM - Opening Ceremonies</p>
              <p>9:30 PM - Sponsorship Fair</p>
              <p>10:30 PM - Hacking Begins</p>
            </div>
            <div className="day">
              <p>Saturday</p>
              <p>12:00 AM - Midnight Snack</p>
              <p>8:00 AM - 9:30 AM - Breakfast</p>
              <p>1:00 PM - 2:30 PM - Lunch</p>
              <p>7:00 PM - 8:30 PM - Dinner</p>
            </div>
            <div className="day">
              <p>Sunday</p>
              <p>12:00 AM - Midnight Snack</p>
              <p>7:30 AM - 8:30 AM - Breakfast</p>
              <p>8:30 AM - Submissions to Devpost</p>
              <p>10:30 AM - Hacking ends and Lunch</p>
              <p>11:00 AM - 2:00 PM - Demo Fair</p>
              <p>2:30 PM - 3:30 PM - Closing Ceremony</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Schedule;