import React, { Component } from 'react';
import Disney from '../assets/boats/Disney-Boat.png';
import GoogleCloud from '../assets/boats/Google-Cloud-Boat.png';
import Microsoft from '../assets/boats/Microsoft-Boat.png'
import FB from '../assets/boats/FB-Boat.png'
import PWC from '../assets/boats/PWC-Boat.png'
import CapitalOne from '../assets/boats/Capital-One-Boat.png'
import RBC from '../assets/boats/RBC-Boat.png'
import Oracle from '../assets/boats/Oracle-Boat.png'
import NSIN from '../assets/boats/NSIN-Boat.png'
import EA from '../assets/boats/EA-Boat.png'
import AnimatedBoat from './AnimatedBoat';

class Sponsor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      second: false,
      third: false,
      fourth: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ second: true })
    }, 10000)
    setTimeout(() => {
      this.setState({ third: true })
    }, 20000)
    setTimeout(() => {
      this.setState({ fourth: true })
    }, 30000)
  }

  render() {
    const { second, third, fourth } = this.state;

    return (
      <div className="kh-sponsor">
        <div className="boat">
          <AnimatedBoat />
        </div>
        <div className="boat-one sm-boat">
          <img src={Disney} alt="disney" />
        </div>
        <div className="boat-two sm-boat">
          <img src={GoogleCloud} alt="google cloud" />
        </div>
        <div className="boat-three sm-boat">
          <img src={Microsoft} alt="microsoft" />
        </div>
        {second ?
          <div>
            <div className="boat-one sm-boat">
              <img src={FB} alt="facebook" />
            </div>
            <div className="boat-two lg-boat">
              <img src={PWC} alt="pwc" />
            </div>
            <div className="boat-three md-boat">
              <img src={CapitalOne} alt="capital one" />
            </div>
          </div>
          : null
        }
        {third ?
          <div>
            <div className="boat-one sm-boat">
              <img src={EA} alt="ea" />
            </div>
            <div className="boat-two sm-boat">
              <img src={NSIN} alt="nsin" />
            </div>
            <div className="boat-three lg-boat">
              <img src={Oracle} alt="oracle" />
            </div>
          </div>
          : null
        }
        {fourth ?
          <div className="boat-one md-boat">
            <img src={RBC} alt="rbc" />
          </div>
          : null
        }
      </div>
    )
  }
}

export default Sponsor;