import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const AcceptancePage = () => {
  return (
    <div className="kh-accept">
      <div className="row no-gutters align-items-center justify-content-center h-100">
        <div className="text-holder">
          <div className="title">
            Thank you for applying!
          </div>
          <div className="description">
            We hope to see you at Knight Hacks in 2020!<br/>
            Stay tuned for updates :)
          </div>
          <Button className="return-button">
            <Link to="/" style={{ color: 'white' }}>
              Return to Home Screen
            </Link>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AcceptancePage;