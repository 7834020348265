import React, { Component } from 'react';
import AnimatedBoat from './AnimatedBoat';

class About extends Component {
  render() {
    return (
      <div className="kh-about">
        <div className="boat">
          <AnimatedBoat />
        </div>
        <div className="description">
          <h2>About Knight Hacks</h2>
          <p>
            {`Connect, Collaborate, and Create With 700 of the brightest developers, engineers, and designers in the south-east. Whether you’re a seasoned hacker or a tech newbie, Knight Hacks welcomes you. Just bring an open mind and an insatiable desire to learn, and we’ll take care of the rest. Create a product, learn new skills, and have fun with friends old and new - all in 36 hours.`}
          </p>
        </div>
      </div>
    )
  }
}

export default About;