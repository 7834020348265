import React, { Component } from 'react';
import logo from '../assets/Logo.svg'
import RegisterButton from './RegisterButton';
import AnimatedBoat from './AnimatedBoat';
import Castle from '../assets/Castle.png'
import GoogleCloud from '../assets/sponsors/Google-Cloud-Logo.png';
import PWC from '../assets/sponsors/PWC-Logo.png';
import Oracle from '../assets/sponsors/Oracle-Logo.svg';
import Disney from '../assets/sponsors/Disney-Logo.png';
import Microsoft from '../assets/sponsors/Microsoft-Logo.png';
import RBC from '../assets/sponsors/RBC-Logo.png';
import Capital from '../assets/sponsors/Capital-Logo.png';
import EA from '../assets/sponsors/EA-Logo.png';
import NSIN from '../assets/sponsors/NSIN-Logo.png';
import FB from '../assets/sponsors/FB-Logo.png';

class Mobile extends Component {
  render() {
    return (
      <div className="kh-mobile row justify-content-center align-items-center no-gutters">
        {/* <a
          id="mlh-trust-badge"
          style={{ display: 'block', maxWidth: '100px', minWidth: '60px', position: 'fixed', right: '50px', top: 0, width: '10%', zIndex: 10000 }}
          href="https://mlh.io/seasons/na-2020/events?utm_source=na-hackathon&utm_medium=TrustBadge&utm_campaign=2020-season&utm_content=white"
          target="_blank"
          rel="noopener noreferrer">
          <img
            src="https://s3.amazonaws.com/logged-assets/trust-badge/2020/mlh-trust-badge-2020-white.svg"
            alt="Major League Hacking 2020 Hackathon Season"
            style={{ width: '100%' }}
          />
        </a> */}
        <div className="home">
          <div className="row logo-holder no-gutters">
            <div className="col-12">
              <img alt="knight hacks logo" src={logo} />
            </div>
            <div className="col-12">
              <div className="date">October 9th - October 11th, 2020</div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center mb-5">
              <RegisterButton />
            </div>
            <div className="col-12">
              <AnimatedBoat mobile />
            </div>
          </div>
        </div>
        <div className="about">
          <p className="title">
            Connect.<br/>Collaborate.<br/>Create.
          </p>
          <p className="description">
            With 700 of the brightest developers, engineers, and designers in the south-east. Whether you’re a seasoned hacker or a tech newbie, Knight Hacks welcomes you. Just bring an open mind and an insatiable desire to learn, and we’ll take care of the rest. Create a product, learn new skills, and have fun with friends old and new - all in 36 hours.
          </p>
        </div>
        <div className="schedule">
          <p className="title">
            Schedule
          </p>
          <div className="schedule-holder">
            <p>Friday</p>
            <div className="day">
              <p>6:30 PM - Check-In</p>
              <p>7:00 PM - Dinner</p>
              <p>8:00 PM - Check-In Ends</p>
              <p>8:30 PM - Opening Ceremonies</p>
              <p>9:30 PM - Sponsorship Fair</p>
              <p>10:30 PM - Hacking Begins</p>
            </div>
            <p>Saturday</p>
            <div className="day">
              <p>12:00 AM - Midnight Snack</p>
              <p>8:00 AM - 9:30 AM - Breakfast</p>
              <p>1:00 PM - 2:30 PM - Lunch</p>
              <p>7:00 PM - 8:30 PM - Dinner</p>
            </div>
            <p>Sunday</p>
            <div className="day">
              <p>12:00 AM - Midnight Snack</p>
              <p>7:30 AM - 8:30 AM - Breakfast</p>
              <p>8:30 AM - Submissions to Devpost</p>
              <p>10:30 AM - Hacking ends and Lunch</p>
              <p>11:00 AM - 2:00 PM - Demo Fair</p>
              <p>2:30 PM - 3:30 PM - Closing Ceremony</p>
            </div>
          </div>
        </div>
        <div className="register">
          <p>Sponsors</p>
          <div className="row justify-content-center sponsor-holder" style={{ margin: '10px' }}>
            <div className="col-12 mb-2">
              <div className="sponsor">
                <img src={PWC} alt="pwc" />
              </div>
            </div>
            <div className="col-12 my-2">
              <div className="sponsor">
                <img src={Oracle} alt="oracle" />
              </div>
            </div>
            <div className="col-6 my-2">
              <div className="sponsor">
                <img src={RBC} alt="rbc" />
              </div>
            </div>
            <div className="col-6 my-2">
              <div className="sponsor">
                <img src={Capital} alt="capital one" />
              </div>
            </div>
            <div className="col-4 my-2">
              <div className="sponsor">
                <img src={GoogleCloud} alt="google" />
              </div>
            </div>
            <div className="col-4 my-2">
              <div className="sponsor">
                <img src={Disney} alt="disney" />
              </div>
            </div>
            <div className="col-4 my-2">
              <div className="sponsor">
                <img src={Microsoft} alt="microsoft" />
              </div>
            </div>
            <div className="col-4 my-2">
              <div className="sponsor">
                <img src={EA} alt="ea" />
              </div>
            </div>
            <div className="col-4 my-2">
              <div className="sponsor">
                <img src={NSIN} alt="ea" />
              </div>
            </div>
            <div className="col-4 my-2">
              <div className="sponsor">
                <img src={FB} alt="facebook" />
              </div>
            </div>
          </div>
          <img className="castle" src={Castle} alt="castle" />
        </div>
      </div>
    )
  }
}

export default Mobile;