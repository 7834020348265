import React, { Component } from 'react';
import AnimatedBoat from './AnimatedBoat';

class Register extends Component {
  render() {
    return (
      <div className="kh-register">
        <div className="boat">
          <AnimatedBoat />
        </div>
      </div>
    )
  }
}

export default Register