import React, { Component } from 'react';
import { Collapse } from 'reactstrap';

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: [],
      faqs: [
        {
          title: "What is Knight Hacks?",
          desc: "Knight Hacks is the University of Central Florida’s massive hackathon, where hundreds of students with different skill levels come together from around the world to experiment and create unique software or hardware projects from scratch. We empower and enable teams to make something great in only 36 hours by providing an abundance of resources like workshops, mentors, and hardware components."
        },
        {
          title: "Who can participate?",
          desc: "Undergraduate and graduate students from any college or university anywhere in the world are eligible to apply to Knight Hacks, as well as those who have graduated in the past 12 months. Unfortunately, Knight Hacks 2020 cannot admit high school students or students under 18 years of age."
        },
        {
          title: "Is Knight Hacks Free?",
          desc: "Admission to Knight Hacks is completely free. Meals, workshops, mentorship, swag, hardware, and snacks are free for the entire event!"
        },
        {
          title: "How many people can be on a team?",
          desc: "You can form teams of up to 4 people. There are no restrictions for team members, so you can team up with hackers of any school, country, or experience level. Teams can be formed before or during the event."
        },
        {
          title: "What if I am a beginner?",
          desc: "Knight Hacks welcomes students of all skill levels. In previous years, about half of the students have attended Knight Hacks as their first hackathon. We’ll have talks, mentors and workshops to help you with your project. Hackathons can be a great place to learn new skills in a short amount of time. Just be eager to learn, and excited to meet lots of awesome people."
        },
        {
          title: "What kind of workshops, talks, and activities will there be?",
          desc: "Previously, we’ve held workshops and talks for a range of skill levels from beginner to advanced like Intro to Web Development and Virtual Reality. We’ve also had introductory workshops to various programming tools such as APIs, databases and platforms. Whether it’s for relaxation or health, novelty or competition, our team has something exciting prepared for you!"
        },
        {
          title: "404: Question Not Found",
          desc: "If your question is not listed here, please feel free to reach out to us at team@knighthacks.org or message the Knight Hacks Facebook or Instagram pages."
        },
        {
          title: "What is the code of conduct for the event?",
          desc: <div>The event uses the MLH code of conduct which can be found <a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf">here</a></div>
        }
      ]
    }
  }

  componentDidMount() {
    const { faqs } = this.state;
    let arr = [];
    arr.push(true);
    for (let i = 1; i < faqs.length; ++i) {
      arr.push(false);
    }
    this.setState({ isOpen: arr });
  }

  toggle = (index) => {
    const { faqs } = this.state;
    let arr = [];
    for(let i = 0; i < faqs.length; ++i){ 
      arr.push(false);
    }
    arr[index] = true;
    this.setState({ isOpen: arr });
  };

  render() {
    const { faqs, isOpen } = this.state;

    return (
      <div className="kh-faq">
        <div className="glacier">
        </div>
        <div className="faq-wrapper">
          <h2>FAQS</h2>
          {faqs.map((e, index) => {
            console.log(e.desc);
            return (
              <div key={`${e.title}-${index}`} className="faq" onClick={() => this.toggle(index)}>
                <p><b>{e.title}</b></p>
                <Collapse isOpen={isOpen[index]}>
                <p>
                  {e.desc}
                </p>
                </Collapse>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default FAQ;