import 'react-app-polyfill/ie9';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/style.css';
import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCfMDsLsbEvp5ekyDUqHSx_brk3nFAoYv8",
  authDomain: "knighthacks-backend.firebaseapp.com",
  databaseURL: "https://knighthacks-backend.firebaseio.com",
  projectId: "knighthacks-backend",
  storageBucket: "knighthacks-backend.appspot.com",
  messagingSenderId: "1095359822390",
  appId: "1:1095359822390:web:d9edc4a40cf7dbcda97697",
  measurementId: "G-2EFFEEGW09"
};

firebase.initializeApp(firebaseConfig);

firebase.analytics().logEvent('page_view');

ReactDOM.render(<App />, document.getElementById('root'));
